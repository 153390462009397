
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { CalendarSettings, dbToCalendarSettings } from "@/lib/supabase";
import { useLocalStorage } from "@/hooks/use-local-storage";

export type UnavailableTimeSlot = {
  start: number;
  end: number;
};

export type UnavailableSlotsMap = {
  [day: string]: UnavailableTimeSlot[];
};

export const useCalendarSettings = (
  user: any | null,
  isClientView: boolean
) => {
  const [localSettings, setLocalSettings] = useLocalStorage<CalendarSettings>(
    "calendar-settings",
    {
      startHour: 8,
      endHour: 20,
      workDays: [1, 2, 3, 4, 5, 6],
      unavailableSlots: {},
    }
  );
  const [calendarSettings, setCalendarSettings] = useState<CalendarSettings>(localSettings);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const fetchSettings = async () => {
      if (!user) {
        return;
      }
      
      setIsLoading(true);
      
      try {
        let settingsQuery;
        
        if (isClientView) {
          const { data: adminData, error: adminError } = await supabase
            .from("profiles")
            .select("id")
            .eq("role", "admin")
            .limit(1);
            
          if (adminError) {
            console.error("Errore nel trovare l'admin:", adminError);
            toast({
              title: "Errore",
              description: "Impossibile caricare le impostazioni del calendario",
              variant: "destructive"
            });
            return;
          }
          
          if (adminData && adminData.length > 0) {
            const adminId = adminData[0].id;
            console.log("Usando impostazioni dell'admin ID:", adminId);
            
            settingsQuery = supabase
              .from("calendar_settings")
              .select("*")
              .eq("user_id", adminId)
              .maybeSingle();
          } else {
            console.log("Nessun admin trovato, uso impostazioni predefinite");
            settingsQuery = supabase
              .from("calendar_settings")
              .select("*")
              .limit(1)
              .maybeSingle();
          }
        } else {
          settingsQuery = supabase
            .from("calendar_settings")
            .select("*")
            .eq("user_id", user.id)
            .maybeSingle();
        }

        const { data, error } = await settingsQuery;

        if (error) {
          console.error("Errore nel caricamento delle impostazioni:", error);
          toast({
            title: "Errore",
            description: "Impossibile caricare le impostazioni del calendario",
            variant: "destructive"
          });
          return;
        }

        if (data) {
          console.log("Impostazioni calendario caricate:", data);
          const dbSettings = dbToCalendarSettings(data);
          setCalendarSettings(dbSettings);
          setLocalSettings(dbSettings);
        } else {
          console.log("Nessuna impostazione trovata, uso valori predefiniti");
        }
      } catch (error) {
        console.error("Errore imprevisto:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, [user, toast, setLocalSettings, isClientView]);

  const saveCalendarSettings = async (settings: CalendarSettings) => {
    if (isClientView) {
      toast({
        title: "Accesso limitato",
        description: "Non hai i permessi per modificare le impostazioni del calendario",
        variant: "destructive"
      });
      return;
    }
    
    if (!settings.startHour || !settings.endHour || !settings.workDays?.length) {
      toast({
        title: "Errore di validazione",
        description: "Compila tutti i campi richiesti",
        variant: "destructive",
      });
      return;
    }

    if (!user) {
      toast({
        title: "Errore di autenticazione",
        description: "Devi essere autenticato per salvare le impostazioni",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsSaving(true);
      
      const updatedSettings = {
        ...settings,
        user_id: user.id
      };
      
      const dbSettings = calendarSettingsToDb(updatedSettings);
      
      console.log("Salvando impostazioni con user_id:", dbSettings.user_id);
      console.log("Fasce orarie non disponibili:", dbSettings.unavailable_slots);
      
      if (calendarSettings.id) {
        const { error } = await supabase
          .from('calendar_settings')
          .update({
            start_hour: dbSettings.start_hour,
            end_hour: dbSettings.end_hour,
            work_days: dbSettings.work_days,
            unavailable_slots: dbSettings.unavailable_slots,
            user_id: dbSettings.user_id
          })
          .eq('id', calendarSettings.id);
          
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('calendar_settings')
          .insert({
            start_hour: dbSettings.start_hour,
            end_hour: dbSettings.end_hour,
            work_days: dbSettings.work_days,
            unavailable_slots: dbSettings.unavailable_slots,
            user_id: dbSettings.user_id
          });
          
        if (error) throw error;
      }
      
      toast({
        title: "Impostazioni salvate",
        description: "Le impostazioni del calendario sono state aggiornate",
      });
      
      setCalendarSettings({
        ...updatedSettings,
        id: calendarSettings.id
      });
      
      setLocalSettings({
        ...updatedSettings,
        id: calendarSettings.id
      });
      
      return true;
    } catch (error) {
      console.error(error);
      toast({
        title: "Errore",
        description: "Impossibile salvare le impostazioni",
        variant: "destructive",
      });
      return false;
    } finally {
      setIsSaving(false);
    }
  };
  
  const addUnavailableSlot = (day: string, slot: UnavailableTimeSlot) => {
    setCalendarSettings(prev => {
      const unavailableSlots = { ...(prev.unavailableSlots || {}) };
      if (!unavailableSlots[day]) {
        unavailableSlots[day] = [];
      }
      
      // Verifica se lo slot è già presente
      const isSlotExists = unavailableSlots[day].some(
        existingSlot => existingSlot.start === slot.start && existingSlot.end === slot.end
      );
      
      if (!isSlotExists) {
        unavailableSlots[day] = [...unavailableSlots[day], slot];
      }
      
      return {
        ...prev,
        unavailableSlots
      };
    });
  };
  
  const removeUnavailableSlot = (day: string, index: number) => {
    setCalendarSettings(prev => {
      const unavailableSlots = { ...(prev.unavailableSlots || {}) };
      if (unavailableSlots[day] && unavailableSlots[day].length > index) {
        unavailableSlots[day] = unavailableSlots[day].filter((_, i) => i !== index);
        
        // Se l'array è vuoto, rimuovi la chiave
        if (unavailableSlots[day].length === 0) {
          delete unavailableSlots[day];
        }
      }
      
      return {
        ...prev,
        unavailableSlots
      };
    });
  };
  
  const clearUnavailableSlots = (day?: string) => {
    setCalendarSettings(prev => {
      if (!day) {
        // Cancella tutti gli slot non disponibili
        return {
          ...prev,
          unavailableSlots: {}
        };
      }
      
      // Cancella solo gli slot del giorno specificato
      const unavailableSlots = { ...(prev.unavailableSlots || {}) };
      delete unavailableSlots[day];
      
      return {
        ...prev,
        unavailableSlots
      };
    });
  };
  
  const isTimeSlotUnavailable = (day: number, hour: number): boolean => {
    const dayStr = day.toString();
    const unavailableSlots = calendarSettings.unavailableSlots || {};
    
    if (!unavailableSlots[dayStr]) {
      return false;
    }
    
    return unavailableSlots[dayStr].some(
      slot => hour >= slot.start && hour < slot.end
    );
  };

  return {
    calendarSettings,
    setCalendarSettings,
    isLoading,
    isSaving,
    saveCalendarSettings,
    addUnavailableSlot,
    removeUnavailableSlot,
    clearUnavailableSlots,
    isTimeSlotUnavailable
  };
};

const calendarSettingsToDb = (settings: CalendarSettings) => {
  return {
    start_hour: settings.startHour,
    end_hour: settings.endHour,
    work_days: settings.workDays,
    unavailable_slots: settings.unavailableSlots || {},
    user_id: settings.user_id
  };
}
