
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { CalendarSettings } from "@/lib/supabase";
import { UnavailableTimeSlot, UnavailableSlotsMap } from "@/hooks/use-calendar-settings";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import { X, Plus, Calendar } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

interface CalendarSettingsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  settings: CalendarSettings;
  onSaveSettings: (settings: CalendarSettings) => void;
}

const weekDays = [
  { id: 1, name: "Lunedì" },
  { id: 2, name: "Martedì" },
  { id: 3, name: "Mercoledì" },
  { id: 4, name: "Giovedì" },
  { id: 5, name: "Venerdì" },
  { id: 6, name: "Sabato" },
  { id: 7, name: "Domenica" },
];

const availableHours = Array.from({ length: 24 }).map((_, i) => i);

// Schema aggiornato per assicurarsi che tutti i campi siano obbligatori
const formSchema = z.object({
  startHour: z.number().min(0).max(23),
  endHour: z.number().min(0).max(23),
  workDays: z.array(z.number().min(1).max(7)).nonempty("Seleziona almeno un giorno"),
});

const CalendarSettingsModal: React.FC<CalendarSettingsModalProps> = ({
  open,
  onOpenChange,
  settings,
  onSaveSettings,
}) => {
  const [activeTab, setActiveTab] = useState("generale");
  const [selectedDay, setSelectedDay] = useState<number>(1);
  const [unavailableStartHour, setUnavailableStartHour] = useState<number>(9);
  const [unavailableEndHour, setUnavailableEndHour] = useState<number>(12);
  const [localUnavailableSlots, setLocalUnavailableSlots] = useState<UnavailableSlotsMap>(
    settings.unavailableSlots || {}
  );
  const [applyToAllDays, setApplyToAllDays] = useState<boolean>(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      startHour: settings.startHour,
      endHour: settings.endHour,
      workDays: settings.workDays,
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    // Validazione: ora di inizio deve essere minore dell'ora di fine
    if (values.startHour >= values.endHour) {
      form.setError("endHour", {
        type: "manual",
        message: "L'ora di fine deve essere successiva all'ora di inizio",
      });
      return;
    }

    // Qui ci assicuriamo che il valore rispetti l'interfaccia CalendarSettings
    // Manteniamo l'id e lo user_id esistenti se presenti
    const updatedSettings: CalendarSettings = {
      ...settings, // Mantieni i campi esistenti come id e user_id
      startHour: values.startHour,
      endHour: values.endHour,
      workDays: values.workDays,
      unavailableSlots: localUnavailableSlots
    };

    onSaveSettings(updatedSettings);
    onOpenChange(false);
  };

  const addUnavailableSlot = () => {
    if (unavailableStartHour >= unavailableEndHour) {
      return; // Non aggiungere slot non validi
    }

    const newSlot: UnavailableTimeSlot = {
      start: unavailableStartHour,
      end: unavailableEndHour
    };

    if (applyToAllDays) {
      const updatedSlots = { ...localUnavailableSlots };
      
      // Applica lo slot a tutti i giorni lavorativi
      form.getValues().workDays.forEach(day => {
        const dayStr = day.toString();
        if (!updatedSlots[dayStr]) {
          updatedSlots[dayStr] = [];
        }
        
        // Verifica se lo slot è già presente
        const isSlotExists = updatedSlots[dayStr].some(
          existingSlot => existingSlot.start === newSlot.start && existingSlot.end === newSlot.end
        );
        
        if (!isSlotExists) {
          updatedSlots[dayStr] = [...updatedSlots[dayStr], newSlot];
        }
      });
      
      setLocalUnavailableSlots(updatedSlots);
    } else {
      const dayStr = selectedDay.toString();
      setLocalUnavailableSlots(prev => {
        const daySlots = prev[dayStr] || [];
        
        // Verifica se lo slot è già presente
        const isSlotExists = daySlots.some(
          existingSlot => existingSlot.start === newSlot.start && existingSlot.end === newSlot.end
        );
        
        if (isSlotExists) {
          return prev; // Lo slot esiste già, non fare nulla
        }
        
        return {
          ...prev,
          [dayStr]: [...daySlots, newSlot]
        };
      });
    }
  };

  const removeUnavailableSlot = (day: string, index: number) => {
    setLocalUnavailableSlots(prev => {
      const updatedSlots = { ...prev };
      
      if (updatedSlots[day] && updatedSlots[day].length > index) {
        updatedSlots[day] = updatedSlots[day].filter((_, i) => i !== index);
        
        // Se l'array è vuoto, rimuovi la chiave
        if (updatedSlots[day].length === 0) {
          delete updatedSlots[day];
        }
      }
      
      return updatedSlots;
    });
  };

  const clearUnavailableSlots = (day?: string) => {
    if (!day) {
      setLocalUnavailableSlots({});
    } else {
      setLocalUnavailableSlots(prev => {
        const updatedSlots = { ...prev };
        delete updatedSlots[day];
        return updatedSlots;
      });
    }
  };

  const getDayUnavailableSlots = (day: number): UnavailableTimeSlot[] => {
    const dayStr = day.toString();
    return localUnavailableSlots[dayStr] || [];
  };

  const formatTimeSlot = (slot: UnavailableTimeSlot): string => {
    return `${slot.start}:00 - ${slot.end}:00`;
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Impostazioni Calendario</DialogTitle>
          <DialogDescription>
            Modifica le ore di lavoro, i giorni della settimana attivi e le fasce orarie non disponibili.
          </DialogDescription>
        </DialogHeader>
        
        <Form {...form}>
          <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
            <TabsList className="grid grid-cols-2 w-full mb-4">
              <TabsTrigger value="generale">Impostazioni Generali</TabsTrigger>
              <TabsTrigger value="fasce-non-disponibili">Fasce Non Disponibili</TabsTrigger>
            </TabsList>
            
            <TabsContent value="generale">
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="startHour"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Ora di inizio</FormLabel>
                        <Select
                          onValueChange={(value) => field.onChange(parseInt(value))}
                          defaultValue={field.value.toString()}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Seleziona ora" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {availableHours.map((hour) => (
                              <SelectItem key={hour} value={hour.toString()}>
                                {hour}:00
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="endHour"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Ora di fine</FormLabel>
                        <Select
                          onValueChange={(value) => field.onChange(parseInt(value))}
                          defaultValue={field.value.toString()}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Seleziona ora" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {availableHours.map((hour) => (
                              <SelectItem key={hour} value={hour.toString()}>
                                {hour}:00
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <FormField
                  control={form.control}
                  name="workDays"
                  render={() => (
                    <FormItem>
                      <FormLabel>Giorni lavorativi</FormLabel>
                      <div className="grid grid-cols-2 gap-2 mt-2">
                        {weekDays.map((day) => (
                          <FormField
                            key={day.id}
                            control={form.control}
                            name="workDays"
                            render={({ field }) => {
                              return (
                                <FormItem
                                  key={day.id}
                                  className="flex flex-row items-start space-x-3 space-y-0"
                                >
                                  <FormControl>
                                    <Checkbox
                                      checked={field.value?.includes(day.id)}
                                      onCheckedChange={(checked) => {
                                        return checked
                                          ? field.onChange([...field.value, day.id])
                                          : field.onChange(
                                              field.value?.filter(
                                                (value) => value !== day.id
                                              )
                                            );
                                      }}
                                    />
                                  </FormControl>
                                  <FormLabel className="font-normal">
                                    {day.name}
                                  </FormLabel>
                                </FormItem>
                              );
                            }}
                          />
                        ))}
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <DialogFooter className="mt-4">
                  <Button type="submit">Salva impostazioni</Button>
                </DialogFooter>
              </form>
            </TabsContent>
            
            <TabsContent value="fasce-non-disponibili">
              <div className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="space-y-4">
                    <div>
                      <FormLabel>Giorno</FormLabel>
                      <Select
                        value={selectedDay.toString()}
                        onValueChange={value => setSelectedDay(parseInt(value))}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona giorno" />
                        </SelectTrigger>
                        <SelectContent>
                          {weekDays.map(day => (
                            <SelectItem key={day.id} value={day.id.toString()}>
                              {day.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <FormLabel>Ora inizio</FormLabel>
                        <Select
                          value={unavailableStartHour.toString()}
                          onValueChange={value => setUnavailableStartHour(parseInt(value))}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Ora inizio" />
                          </SelectTrigger>
                          <SelectContent>
                            {availableHours.map(hour => (
                              <SelectItem 
                                key={`start-${hour}`} 
                                value={hour.toString()}
                                disabled={hour >= unavailableEndHour}
                              >
                                {hour}:00
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                      
                      <div>
                        <FormLabel>Ora fine</FormLabel>
                        <Select
                          value={unavailableEndHour.toString()}
                          onValueChange={value => setUnavailableEndHour(parseInt(value))}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Ora fine" />
                          </SelectTrigger>
                          <SelectContent>
                            {availableHours.map(hour => (
                              <SelectItem 
                                key={`end-${hour}`} 
                                value={hour.toString()}
                                disabled={hour <= unavailableStartHour}
                              >
                                {hour}:00
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    
                    <div className="flex items-center space-x-2">
                      <Checkbox 
                        id="apply-all" 
                        checked={applyToAllDays} 
                        onCheckedChange={checked => setApplyToAllDays(checked === true)}
                      />
                      <label 
                        htmlFor="apply-all" 
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Applica a tutti i giorni lavorativi
                      </label>
                    </div>
                    
                    <div className="flex flex-wrap gap-2">
                      <Button 
                        type="button" 
                        onClick={addUnavailableSlot}
                        disabled={unavailableStartHour >= unavailableEndHour}
                        className="mt-2"
                      >
                        <Plus className="h-4 w-4 mr-1" /> Aggiungi fascia
                      </Button>
                      
                      <Button 
                        type="button" 
                        variant="outline" 
                        onClick={() => clearUnavailableSlots(selectedDay.toString())}
                        className="mt-2"
                      >
                        Rimuovi tutte le fasce per {weekDays.find(d => d.id === selectedDay)?.name}
                      </Button>
                    </div>
                  </div>
                  
                  <div className="border rounded-md p-4 bg-muted/20 space-y-4">
                    <h3 className="font-medium flex items-center">
                      <Calendar className="h-4 w-4 mr-2" /> 
                      Fasce non disponibili
                    </h3>
                    
                    {Object.keys(localUnavailableSlots).length === 0 ? (
                      <p className="text-sm text-muted-foreground">Nessuna fascia non disponibile configurata</p>
                    ) : (
                      <div className="space-y-4">
                        {weekDays.map(day => {
                          const slots = getDayUnavailableSlots(day.id);
                          if (slots.length === 0) return null;
                          
                          return (
                            <div key={day.id} className="space-y-2">
                              <h4 className="text-sm font-medium">{day.name}</h4>
                              <div className="flex flex-wrap gap-2">
                                {slots.map((slot, index) => (
                                  <Badge 
                                    key={index} 
                                    variant="secondary"
                                    className="flex items-center gap-1 px-2 py-1"
                                  >
                                    {formatTimeSlot(slot)}
                                    <button
                                      onClick={() => removeUnavailableSlot(day.id.toString(), index)}
                                      className="h-4 w-4 rounded-full hover:bg-muted/50 inline-flex items-center justify-center"
                                    >
                                      <X className="h-3 w-3" />
                                    </button>
                                  </Badge>
                                ))}
                              </div>
                            </div>
                          );
                        })}
                        
                        <div className="pt-2">
                          <Button 
                            type="button" 
                            variant="destructive" 
                            onClick={() => clearUnavailableSlots()}
                            size="sm"
                            className="mt-2"
                          >
                            Rimuovi tutte le fasce
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                <DialogFooter>
                  <Button onClick={form.handleSubmit(onSubmit)}>Salva impostazioni</Button>
                </DialogFooter>
              </div>
            </TabsContent>
          </Tabs>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CalendarSettingsModal;
