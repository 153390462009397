
import React, { useState, useMemo } from "react";
import { addDays, format, startOfWeek, isSameDay, addHours } from "date-fns";
import { it } from "date-fns/locale";
import { CalendarSettings } from "@/lib/supabase";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, Settings, AlertCircle, Trash2, Calendar as CalendarIcon } from "lucide-react";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import SessionBookingDialog from "./SessionBookingDialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useIsMobile } from "@/hooks/use-mobile";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface CalendarEvent {
  id: string;
  title: string;
  date: Date;
  hour: number;
  minute: number;
  courseId?: string;
  clientId?: string;
  remainingHours?: number;
  type?: string;
  colorCode?: string;
}

interface TimeSlot {
  date: Date;
  hour: number;
}

interface WeeklyCalendarProps {
  currentDate: Date;
  onDateChange: (date: Date) => void;
  onOpenSettings: () => void;
  settings: CalendarSettings;
  events?: CalendarEvent[];
  onAddEvent?: (event: Omit<CalendarEvent, "id">) => Promise<void>;
  onDeleteEvent?: (event: CalendarEvent) => Promise<void>;
}

const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({
  currentDate,
  onDateChange,
  onOpenSettings,
  settings,
  events = [],
  onAddEvent,
  onDeleteEvent
}) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(null);
  const [isBookingDialogOpen, setIsBookingDialogOpen] = useState(false);
  const [isConflictDialogOpen, setIsConflictDialogOpen] = useState(false);
  const [conflictingEvents, setConflictingEvents] = useState<CalendarEvent[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<CalendarEvent | null>(null);
  const [activeTab, setActiveTab] = useState("week");
  const isMobile = useIsMobile();

  const startDate = useMemo(() => {
    return startOfWeek(currentDate, {
      weekStartsOn: 1
    });
  }, [currentDate]);

  const weekDays = useMemo(() => {
    return Array.from({
      length: 7
    }).map((_, index) => {
      const day = addDays(startDate, index);
      return {
        date: day,
        dayName: format(day, "EEEE", {
          locale: it
        }),
        dayShort: format(day, "E", {
          locale: it
        }),
        dayNumber: format(day, "d", {
          locale: it
        }),
        isToday: isSameDay(day, new Date())
      };
    });
  }, [startDate]);

  const hours = useMemo(() => {
    const result = [];
    if (!settings.startHour || !settings.endHour) {
      return [];
    }
    const {
      startHour,
      endHour
    } = settings;
    for (let hour = startHour; hour <= endHour; hour++) {
      result.push(hour);
    }
    return result;
  }, [settings]);

  const goToPrevWeek = () => {
    onDateChange(addDays(startDate, -7));
  };

  const goToNextWeek = () => {
    onDateChange(addDays(startDate, 7));
  };

  const goToPrevDay = () => {
    onDateChange(addDays(currentDate, -1));
  };

  const goToNextDay = () => {
    onDateChange(addDays(currentDate, 1));
  };

  const visibleDays = useMemo(() => {
    if (activeTab === "day" || (isMobile && activeTab === "week")) {
      return weekDays.filter(day => isSameDay(day.date, currentDate));
    }
    return weekDays.filter((_, index) => 
      !settings.workDays || settings.workDays.includes(index + 1)
    );
  }, [weekDays, currentDate, settings.workDays, activeTab, isMobile]);

  const getEventsForDateAndHour = (date: Date, hour: number) => {
    return events.filter(event => isSameDay(event.date, date) && event.hour === hour);
  };

  const handleTimeSlotClick = (date: Date, hour: number) => {
    if (!onAddEvent) return;
    const existingEvents = getEventsForDateAndHour(date, hour);
    if (existingEvents.length > 0) {
      setConflictingEvents(existingEvents);
      setSelectedTimeSlot({
        date,
        hour
      });
      setIsConflictDialogOpen(true);
    } else {
      setSelectedTimeSlot({
        date,
        hour
      });
      setIsBookingDialogOpen(true);
    }
  };

  const handleConflictConfirm = () => {
    setIsConflictDialogOpen(false);
    setIsBookingDialogOpen(true);
  };

  const handleConflictCancel = () => {
    setIsConflictDialogOpen(false);
    setSelectedTimeSlot(null);
  };

  const handleBookingClose = () => {
    setIsBookingDialogOpen(false);
    setSelectedTimeSlot(null);
  };

  const handleBookingConfirm = async (clientId: string, packageId: string, title: string, remainingHours: number) => {
    if (!selectedTimeSlot || !onAddEvent) return;
    try {
      await onAddEvent({
        title,
        date: selectedTimeSlot.date,
        hour: selectedTimeSlot.hour,
        minute: 0,
        clientId,
        remainingHours,
        type: "session"
      });
      setIsBookingDialogOpen(false);
      setSelectedTimeSlot(null);
    } catch (error) {
      console.error("Errore durante la creazione dell'evento:", error);
    }
  };

  const handleDeleteClick = (event: CalendarEvent, e: React.MouseEvent) => {
    e.stopPropagation();
    setEventToDelete(event);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!eventToDelete || !onDeleteEvent) {
      setDeleteDialogOpen(false);
      return;
    }
    try {
      await onDeleteEvent(eventToDelete);
      setDeleteDialogOpen(false);
      setEventToDelete(null);
    } catch (error) {
      console.error("Errore durante l'eliminazione dell'evento:", error);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setEventToDelete(null);
  };
  
  // Verifica se una fascia oraria è marcata come non disponibile
  const isTimeSlotUnavailable = (day: Date, hour: number): boolean => {
    const dayOfWeek = ((day.getDay() - 1) + 7) % 7 + 1; // Converte da 0-6 (dom-sab) a 1-7 (lun-dom)
    const unavailableSlots = settings.unavailableSlots || {};
    const daySlots = unavailableSlots[dayOfWeek.toString()];
    
    if (!daySlots || daySlots.length === 0) {
      return false;
    }
    
    return daySlots.some(slot => hour >= slot.start && hour < slot.end);
  };

  return (
    <div className="flex flex-col gap-4 w-full max-w-full overflow-hidden">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          {activeTab === "week" && (
            <>
              <Button variant="outline" size="icon" onClick={goToPrevWeek}>
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <div className="font-medium hidden sm:block">
                {format(startDate, "d MMMM", { locale: it })} - {format(addDays(startDate, 6), "d MMMM yyyy", { locale: it })}
              </div>
              <div className="font-medium sm:hidden">
                {format(startDate, "d MMM", { locale: it })} - {format(addDays(startDate, 6), "d MMM", { locale: it })}
              </div>
              <Button variant="outline" size="icon" onClick={goToNextWeek}>
                <ChevronRight className="h-4 w-4" />
              </Button>
            </>
          )}
          
          {activeTab === "day" && (
            <>
              <Button variant="outline" size="icon" onClick={goToPrevDay}>
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <div className="font-medium">
                {format(currentDate, "EEEE d MMMM yyyy", { locale: it })}
              </div>
              <Button variant="outline" size="icon" onClick={goToNextDay}>
                <ChevronRight className="h-4 w-4" />
              </Button>
            </>
          )}
        </div>
        
        <Tabs 
          value={activeTab} 
          onValueChange={setActiveTab}
          className="w-auto"
        >
          <TabsList className="grid w-auto grid-cols-2">
            <TabsTrigger value="week">
              <span className="hidden sm:inline">Settimana</span>
              <span className="sm:hidden">Sett</span>
            </TabsTrigger>
            <TabsTrigger value="day">
              <span className="hidden sm:inline">Giorno</span>
              <span className="sm:hidden">Giorno</span>
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      <div className="border rounded-md overflow-hidden w-full">
        <div className="grid border-b overflow-hidden" style={{
          gridTemplateColumns: `minmax(50px, auto) repeat(${visibleDays.length}, minmax(80px, 1fr))`
        }}>
          <div className="py-2 px-2 sm:px-4 border-r font-medium bg-muted text-center">Ora</div>
          {visibleDays.map((day, index) => (
            <div 
              key={index} 
              className={`py-2 px-2 sm:px-4 border-r font-medium text-center ${
                day.isToday ? "bg-primary/10" : "bg-muted"
              } ${index === visibleDays.length - 1 ? "border-r-0" : ""}`}
            >
              <div className="hidden sm:block">{day.dayName}</div>
              <div className="sm:hidden text-sm">{day.dayShort}</div>
              <div className={`text-base sm:text-lg ${day.isToday ? "text-primary font-bold" : ""}`}>
                {day.dayNumber}
              </div>
            </div>
          ))}
        </div>

        {hours.length > 0 && (
          <ScrollArea className="h-[calc(100vh-400px)]">
            <div className="w-full">
              {hours.map(hour => (
                <div 
                  key={hour} 
                  className="grid border-b last:border-b-0" 
                  style={{
                    gridTemplateColumns: `minmax(50px, auto) repeat(${visibleDays.length}, minmax(80px, 1fr))`
                  }}
                >
                  <div className="p-2 border-r font-medium bg-muted text-center">
                    {hour}:00
                  </div>
                  {visibleDays.map((day, index) => {
                    const cellEvents = getEventsForDateAndHour(day.date, hour);
                    const isUnavailable = isTimeSlotUnavailable(day.date, hour);
                    
                    return (
                      <div 
                        key={index} 
                        className={`p-1 sm:p-2 border-r min-h-[70px] sm:min-h-[80px] relative ${
                          day.isToday ? "bg-primary/5" : ""
                        } ${index === visibleDays.length - 1 ? "border-r-0" : ""} ${
                          onAddEvent && !isUnavailable ? "cursor-pointer hover:bg-muted/50" : 
                          isUnavailable ? "cursor-not-allowed" : ""
                        }`} 
                        onClick={onAddEvent && !isUnavailable ? () => handleTimeSlotClick(day.date, hour) : undefined}
                      >
                        {/* Rendering degli eventi esistenti */}
                        {cellEvents.map(event => (
                          <div 
                            key={event.id} 
                            className="p-1 sm:p-1.5 rounded text-white mb-1 shadow-sm hover:opacity-90 transition-colors group relative" 
                            style={{
                              background: event.colorCode || '#3b82f6'
                            }}
                          >
                            <div className="font-medium truncate text-xs sm:text-sm">
                              {event.title}
                            </div>
                            <div className="text-xs opacity-90 flex justify-between">
                              <span className="text-xs">
                                {event.hour.toString().padStart(2, '0')}:{event.minute?.toString().padStart(2, '0')}
                              </span>
                              {event.remainingHours !== undefined && (
                                <span className="text-xs hidden sm:inline">
                                  Ore: {event.remainingHours}
                                </span>
                              )}
                            </div>
                            
                            {onDeleteEvent && (
                              <button 
                                className="absolute right-1 top-1 opacity-0 group-hover:opacity-100 p-0.5 rounded-full bg-red-500/20 hover:bg-red-500 text-white transition-all"
                                onClick={e => handleDeleteClick(event, e)}
                              >
                                <Trash2 className="h-3.5 w-3.5" />
                              </button>
                            )}
                          </div>
                        ))}
                        
                        {/* Overlay per fasce non disponibili */}
                        {isUnavailable && (
                          <div className="absolute inset-0 bg-neutral-100 bg-[repeating-linear-gradient(45deg,#888,#888_1px,transparent_1px,transparent_10px)] opacity-20 flex items-center justify-center">
                            <div className="hidden sm:block bg-white/80 px-2 py-0.5 rounded text-xs text-neutral-600">
                              Non disponibile
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </ScrollArea>
        )}
      </div>

      <AlertDialog open={isConflictDialogOpen} onOpenChange={setIsConflictDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Attenzione: Sovrapposizione di Sessioni</AlertDialogTitle>
            <AlertDialogDescription>
              <div className="flex items-start gap-2 mb-4">
                <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                <div>
                  Ci sono già {conflictingEvents.length} sessioni programmate in questa fascia oraria:
                </div>
              </div>
              <ul className="list-disc pl-5 space-y-1 mb-4">
                {conflictingEvents.map(event => <li key={event.id}>
                    <span className="font-medium">{event.title}</span> 
                    {event.remainingHours !== undefined && <span className="text-sm"> (Ore residue: {event.remainingHours})</span>}
                  </li>)}
              </ul>
              <p>Vuoi comunque programmare una nuova sessione in questa fascia oraria?</p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleConflictCancel}>Annulla</AlertDialogCancel>
            <AlertDialogAction onClick={handleConflictConfirm}>Continua</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <SessionBookingDialog 
        open={isBookingDialogOpen} 
        onOpenChange={setIsBookingDialogOpen} 
        onClose={handleBookingClose} 
        onConfirm={handleBookingConfirm} 
        selectedDate={selectedTimeSlot?.date} 
        selectedHour={selectedTimeSlot?.hour} 
      />
      
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Conferma Eliminazione</AlertDialogTitle>
            <AlertDialogDescription>
              <div className="space-y-2">
                <p>
                  Sei sicuro di voler eliminare questa sessione?
                  {eventToDelete?.type === "session" && eventToDelete?.clientId && <span className="font-medium block mt-2 text-yellow-600">
                      Questa è una sessione personale. Eliminandola, l'ora verrà riaccreditata al pacchetto del cliente.
                    </span>}
                </p>
                {eventToDelete && <div className="p-3 bg-muted rounded-md mt-2">
                    <p className="font-medium">{eventToDelete.title}</p>
                    <p className="text-sm mt-1">
                      {eventToDelete.date ? format(eventToDelete.date, "dd/MM/yyyy", {
                    locale: it
                  }) : ""} - 
                      {eventToDelete.hour}:{eventToDelete.minute?.toString().padStart(2, '0')}
                    </p>
                  </div>}
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleDeleteCancel}>Annulla</AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteConfirm} className="bg-red-500 hover:bg-red-600">
              Elimina
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default WeeklyCalendar;
